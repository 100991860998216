import { awsImagePrefix, ibmImagePrefix } from 'configs/image';
import React, { CSSProperties, memo, useEffect, useRef } from 'react'
import { defaultImage } from './defaultImage';

const Image = memo(({
    style,
    className,
    src,
    lazy = true,            // 懒加载
    retry = 3,
    timeout = 999999,       // 超时时间, 单位 ms
    onTimeout,              // 超时触发事件
    onFinish,
    onRetry,
}: {
    style?: CSSProperties;
    className?: string;
    src: string;
    lazy?: boolean;
    retry?: number;
    timeout?: number;
    onTimeout?: () => void;
    onFinish?: (err?: string) => void;
    onRetry?: (img: HTMLImageElement, src: string) => void;
}) => {
    const statusRef = useRef<'loading' | 'finish'>('loading')
    const imgRef = useRef<HTMLImageElement>(null)

    useEffect(() => {
        const imgEl = imgRef.current
        if (!imgEl) return

        if (src.includes(ibmImagePrefix)) {
            window.nornaCallIbm = (window.nornaCallIbm || 0) + 1
        } else if (src.includes(awsImagePrefix)) {
            window.nornaCallAws = (window.nornaCallAws || 0) + 1
        }

        setTimeout(() => {
            // 超过指定时间, 图片仍在加载中, 调用超时函数
            if (statusRef.current === 'loading') {
                onTimeout?.()
            }
        }, timeout)

        imgEl.onload = e => {
            statusRef.current = 'finish'
            onFinish?.()
        }
        
        imgEl.onerror = (e: any) => {
            let retryCount = parseInt(e.target.getAttribute('retry') || '0', 10)

            // 如果重试次数大于3
            if (retryCount >= retry) {
                // 清除定时器
                statusRef.current = 'finish'
                imgEl.src = defaultImage
                if (typeof onTimeout === 'function') {
                    onTimeout?.()
                } else {
                    onFinish?.('failed.')
                }
            } else {
                // 计数器+1
                retryCount += 1
                imgEl.setAttribute('retry', retryCount.toString())
                if (typeof onRetry === 'function') {
                    onRetry?.(imgEl, src)
                } else {
                    imgEl.src = src
                }
            }
        }
    }, [])

    return (
        <img
            style={style}
            className={className}
            ref={imgRef}
            src={src} 
            alt=""
            loading={ lazy ? 'lazy' : 'eager' }
        />
    )
})

export default Image
