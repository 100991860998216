import React, { ReactNode, forwardRef, memo, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react'
import { createChart, DeepPartial, IChartApi, PriceScaleOptions } from 'norna-lightweight-charts'
import { commonOptions, commonPriceScaleOptions, formatTimeRangeToStr, getMaxDateRange, getTooltipLabelList, handleChartTooltip, isAdjustYAxisVisibleRange, legendColors } from './index.util'
import ShortcutDateGroup from './ShortcutDateGroup'
import styles from './index.module.scss'
import { Spin } from 'druikit'
import classNames from 'classnames'
import { IS_LIVE_ENV } from 'consts'
import ShortcutDateGroupV2 from './ShortcutDateGroupV2'
import { numberUtils } from 'norna-uikit'
import { Flex, Slider } from 'antd'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { usePageDate } from 'hooks/usePageDate'

const financialChartWrapperClassName = 'financial-chart-wrapper'

export type ChartDataType = {
    label: string;                          // 每条线代表什么含义
    data: { time: string; value?: number }[];
    yAxisName: string;                      // y 轴名称
    yAxisPosition?: 'left' | 'right';       // y 轴位置, 默认值为 left
}[]

export interface FinancialChartProps {
    loading?: boolean;
    chartData?: ChartDataType;
    formatTooltipValue?: (value: number, index: number) => string;
    sortTooltipLabel?: (value: string[]) => string[];
    formatYAxis?: (value: number, yAxisName: string) => string;
    /**
     * slider 相关属性
     */
    formatStep?: (metric: string) => number;
    step?: number;
    leftSliderVisible?: boolean;
    rightSliderVisible?: boolean;
    defaultDateRange?: string;
    defaultPriceScaleVisibleRange?: any;
    defaultLeftPriceScaleMaxRange?: any;
    height?: number;
    showShortcutDate?: boolean;
    legendAfter?: ReactNode;
    onDateRangeChange?: (value: string) => void;
    tmpDateRangeValue?: string;
    leftPriceScaleOptions?: DeepPartial<PriceScaleOptions> & { 
        minValue?: number, 
        maxFixedValue?: number, 
        minFixedValue?: number, 
    };
}

export interface FinancialChartRefProps {
    updateVisibleTimeRange: () => void;
    updatePriceScaleVisibleRange: () => void;
    updateChartHeight: () => void;
    getPriceScaleVisibleRange: () => any;
    getLeftPriceScaleMaxRange: () => any;
}

const FinancialChart = forwardRef<FinancialChartRefProps, FinancialChartProps>(({
    /**
     * 线图数据是否正在加载中
     */
    loading = false,
    /**
     * 线图数据源
     */
    chartData = [],
    /**
     * 格式化 tooltip 数值
     */
    formatTooltipValue,
    /**
     * label 排序
     */
    sortTooltipLabel,
    /**
     * 格式化 y 轴
     */
    formatYAxis,
    /**
     * 根据 yAxisName 格式化 step
     */
    formatStep,
    /**
     * yAxisName 如果只有一个, 可以直接设置步长
     */
    step = 1,
    /**
     * 左边 slider 是否可见
     */
    leftSliderVisible = true,
    /**
     * 右边 slider 是否可见
     */
    rightSliderVisible = true,
    /**
     * 组件外部设置默认日期范围
     */
    defaultDateRange = '',
    /**
     * 组件外部设置默认价格范围
     */
    defaultPriceScaleVisibleRange,
    defaultLeftPriceScaleMaxRange,
    /**
     * 组件外部设置线图高度
     */
    height,
    /**
     * 是否显示快捷日期
     */
    showShortcutDate = true,
    /**
     * legend 后置内容
     */
    legendAfter,
    /**
     * 获取组件内部 x 轴日期范围
     */
    onDateRangeChange,
    tmpDateRangeValue,
    leftPriceScaleOptions,
}, ref) => {
    const chartRef = useRef<IChartApi | null>(null)
    const chartContainerRef = useRef<HTMLDivElement>(null)

    /**
     * 线图的高度
     * legend 可能会换行, 换行的的时候总高度是不变的, 那么线图的高度需要相对变小一点
     */
    const [ chartHeight, setChartHeight ] = useState(height || 0)
    // 每条线代表含义
    const [ legendList, setLegendList ] = useState<string[]>([])
    // x 轴日期范围
    const { pageDate } = usePageDate()
    // 
    const [ dateVisibleRange, setDateVisibleRange ] = useState(defaultDateRange)
    const [ dateSelectedRange, setDateSelectedRange ] = useState(defaultDateRange || pageDate)
    // y 轴数值范围
    const [ priceScaleVisibleRange, setPriceScaleVisibleRange ] = useState<any>()
    const [ leftPriceScaleMaxRange, setLeftPriceScaleMaxRange ] = useState<any>({ minValue: 0, maxValue: 0 })
    const [ leftPriceScaleValue, setLeftPriceScaleValue ] = useState({ minValue: 0, maxValue: 0 })
    const [ leftStep, setLeftStep ] = useState(0.1)
    const [ rightPriceScaleMaxRange, setRightPriceScaleMaxRange ] = useState<any>({ minValue: 0, maxValue: 0 })
    const [ rightPriceScaleValue, setRightPriceScaleValue ] = useState({ minValue: 0, maxValue: 0 })
    const [ rightStep, setRightStep ] = useState(0.1)
    const [ leftYAxisName, setLeftYAxisName ] = useState('')
    const [ rightYAxisName, setRightYAxisName ] = useState('')
    // 数据源最大日期范围
    const maxDateRange = getMaxDateRange(chartData)

    /**
     * 数据源变化时, 需要重置 y 轴缓存数据
     */
    useDeepCompareEffect(() => {
        if (!chartData?.length) return

        // 获取左边 y 轴数据源
        const leftChartData = chartData?.filter(item => item.yAxisPosition !== 'right') || []
        if (leftChartData?.length) {
            const axisName = leftChartData?.[0]?.yAxisName
            /**
             * Marketplace seller analytics 要求点开的线图弹窗中 y 轴初始范围和外面的线图 y 轴初始范围一致
             * defaultPriceScaleVisibleRange 设置 y 轴初始范围
             */
            if (defaultPriceScaleVisibleRange?.[axisName]) {
                const priceRange = {
                    minValue: defaultPriceScaleVisibleRange[axisName]?.minValue || 0,
                    maxValue: defaultPriceScaleVisibleRange[axisName]?.maxValue,
                }
                setLeftPriceScaleMaxRange({ 
                    maxValue: defaultLeftPriceScaleMaxRange?.maxValue || priceRange.maxValue, 
                    minValue: 0.01, 
                })
                setLeftPriceScaleValue(priceRange) 
            } else {
                const valueArr = leftChartData
                    .map(item => item.data?.map(item2 => item2?.value || 0))
                    .flat(2) as number[]
                
                let minValue = Math.min(...valueArr)
                if (leftPriceScaleOptions?.minValue) {
                    minValue = Math.max(leftPriceScaleOptions?.minValue, minValue)
                }
                if (leftPriceScaleOptions?.minFixedValue) {
                    minValue = leftPriceScaleOptions?.minFixedValue
                }

                let maxValue = Math.max(...valueArr)
                if (leftPriceScaleOptions?.maxValue) {
                    maxValue = Math.min(leftPriceScaleOptions?.maxValue, maxValue)
                }
                if (leftPriceScaleOptions?.maxFixedValue) {
                    maxValue = leftPriceScaleOptions?.maxFixedValue
                }

                const priceRange = {
                    minValue: minValue === 0 ? 0.01 : minValue,
                    maxValue,
                }

                // y 轴名称变化时, 重置 y 轴
                if (leftYAxisName !== axisName) {
                    setLeftPriceScaleMaxRange({ ...priceRange, minValue: 0.01 })
                    setLeftPriceScaleValue(priceRange)
                } else {
                    // 数值为 0
                    if (leftPriceScaleMaxRange.minValue === 0 && leftPriceScaleMaxRange.maxValue === 0) {
                        setLeftPriceScaleMaxRange({ ...priceRange, minValue: 0.01 })
                    } else {
                        setLeftPriceScaleMaxRange({
                            minValue: 0.01,
                            maxValue: Math.max(priceRange.maxValue, leftPriceScaleMaxRange.maxValue),
                        })
                    }
    
                    const isAdjust = isAdjustYAxisVisibleRange({ chartData: leftChartData, priceScaleValue: leftPriceScaleValue })
                    // leftPriceScaleValue 没有值, 或者 leftPriceScaleValue 与 priceRange 没有交集时才会重置范围
                    if (!leftPriceScaleValue?.maxValue || isAdjust) {
                        setLeftPriceScaleValue(priceRange) 
                    }
                }
            }
            const leftStep = formatStep?.(axisName) || step
            setLeftStep(leftStep)
        }

        // 获取右边 y 轴数据源
        const rightChartData = chartData?.filter(item => item.yAxisPosition === 'right') || []
        if (rightChartData?.length) {
            const axisName = rightChartData[0].yAxisName

            const valueArr = rightChartData
                .map(item => item.data?.map(item2 => item2?.value || 0))
                .flat(2) as number[]
            const minValue = Math.min(...valueArr)
            const priceRange = {
                minValue: minValue === 0 ? 0.01 : minValue,
                maxValue: Math.max(...valueArr),
            }

            if (rightYAxisName !== axisName) {
                setRightPriceScaleMaxRange({ ...priceRange, minValue: 0.01 })
                setRightPriceScaleValue(priceRange)
            } else {
                if (rightPriceScaleMaxRange.minValue === 0 && rightPriceScaleMaxRange.maxValue === 0) {
                    setRightPriceScaleMaxRange({ ...priceRange, minValue: 0.01 })
                } else {
                    setRightPriceScaleMaxRange({
                        minValue: 0.01,
                        maxValue: Math.max(priceRange.maxValue, rightPriceScaleMaxRange.maxValue),
                    })
                }
    
                const isAdjust = isAdjustYAxisVisibleRange({ chartData: rightChartData, priceScaleValue: rightPriceScaleValue })
                // rightPriceScaleValue 没有值, 或者 rightPriceScaleValue 与 priceRange 没有交集时才会重置范围
                if (!rightPriceScaleValue?.maxValue || isAdjust) {
                    setRightPriceScaleValue(priceRange)  
                }
            }

            const rightStep = formatStep?.(axisName) || step
            setRightStep(rightStep)
        }
    }, [ chartData, [] ])

    useLayoutEffect(() => {
        const chartContainerEl = chartContainerRef.current
        if (!chartContainerEl || !chartHeight) return

        // 获取每条线的名称列表
        const tooltipLabelList = getTooltipLabelList({ chartData, sortTooltipLabel })
        setLegendList(tooltipLabelList)

        // 获取左边 y 轴名称
        const leftYAxisChartData = chartData.filter(item => item.yAxisPosition !== 'right')
        const leftYAxisName = leftYAxisChartData?.[0]?.yAxisName || ''
        setLeftYAxisName(leftYAxisName)

        // 获取右边 y 轴名称
        const rightYAxisChartData = chartData.filter(item => item.yAxisPosition === 'right')
        const rightYAxisName = rightYAxisChartData?.[0]?.yAxisName || ''
        setRightYAxisName(rightYAxisName)

        chartRef.current = createChart(chartContainerEl, {
            ...commonOptions as any,
            width: chartContainerRef.current.clientWidth,
            height: height || chartHeight,
            leftPriceScale: {
                // 左边 y 轴是否可见
                visible: leftYAxisChartData?.length,
                ...commonPriceScaleOptions,
                ...leftPriceScaleOptions,
            },
            rightPriceScale: {
                // 右边 y 轴是否可见
                visible: rightYAxisChartData?.length,
                ...commonPriceScaleOptions,
            },
            localization: {
                // 左边 y 轴格式化
                leftPriceFormatter: (value) => {
                    if (typeof formatYAxis === 'function') {
                        return formatYAxis(value, leftYAxisName)
                    }
                    return numberUtils.formatNumber(value, { isCommaSymbol: true, decimal: 2 })
                },
                // 右边 y 轴格式化
                rightPriceFormatter: (value) => {
                    if (typeof formatYAxis === 'function') {
                        return formatYAxis(value, rightYAxisName)
                    }
                    return numberUtils.formatNumber(value, { isCommaSymbol: true, decimal: 2 })
                },
            },
            handleScroll: {
                // 线图是否可以上下可拖动
                vertPressedMouseMove: false,
            },
            handleScale: {
                // y 轴是否可以上下拖动
				axisPressedMouseMove: {
					price: false,
				},
			},
        })

        let seriesArr: any[] = []

        if (chartData?.length) {
            // 生成每一条线段
            tooltipLabelList.forEach((label, index) => {
                // 获取线段对应的数据
                const item = chartData.find(c => c.label === label)
                if (!item) return false
                const color = legendColors[index]

                const seriesOptions: any = {
                    lineWidth: 2, 
                    lineColor: color,
                    topColor: 'transparent', 
                    bottomColor: 'transparent',
                    priceLineVisible: false, 
                    lastValueVisible: false,
                    priceScaleId: item.yAxisPosition || 'left',
                }

                // 左边 y 轴
                if (item.yAxisPosition !== 'right') {
                    seriesOptions.autoscaleInfoProvider = (original) => {
                        const res = original()
                        if (res !== null) {
                            res.priceRange.minValue = leftPriceScaleValue.minValue || res.priceRange.minValue
                            res.priceRange.maxValue = leftPriceScaleValue.maxValue || res.priceRange.maxValue
                        }
                        return res
                    }
                }

                // 右边 y 轴
                if (item.yAxisPosition === 'right') {
                    seriesOptions.autoscaleInfoProvider = (original) => {
                        const res = original()
                        if (res !== null) {
                            res.priceRange.minValue = rightPriceScaleValue.minValue || res.priceRange.minValue
                            res.priceRange.maxValue = rightPriceScaleValue.maxValue || res.priceRange.maxValue
                        }
                        return res
                    }
                }

                if (!seriesOptions?.autoscaleInfoProvider && !IS_LIVE_ENV) {
                    seriesOptions.autoscaleInfoProvider = (original) => {
                        const res = original()
                        if (res !== null) {
                            const minValue = res.priceRange.minValue || 0
                            res.priceRange.minValue = 0
                            let maxValue = res.priceRange.maxValue + minValue
                            if (item.yAxisPosition !== 'right' && leftPriceScaleOptions?.maxValue) {
                                maxValue = Math.min(res.priceRange.maxValue + minValue, leftPriceScaleOptions.maxValue * (1 - commonPriceScaleOptions.scaleMargins.top))
                            }
                            res.priceRange.maxValue = maxValue
                        }
                        return res
                    }
                }

                const series = chartRef.current?.addAreaSeries(seriesOptions)
                series?.setData(item.data)
                seriesArr.push(series)

                if (dateVisibleRange) {
                    chartRef.current?.timeScale()?.setVisibleRange({
                        from: dateVisibleRange.split('_')[0],
                        to: dateVisibleRange.split('_')[1],
                    })
                }
            })
        }

        if (!tmpDateRangeValue && !dateVisibleRange && maxDateRange) {
            chartRef.current?.timeScale()?.setVisibleRange({
                from: maxDateRange.split('_')[0],
                to: maxDateRange.split('_')[1],
            })
            setDateVisibleRange(maxDateRange)
            onDateRangeChange?.(maxDateRange)
        }

        if (seriesArr?.length && tmpDateRangeValue) {
            // try {
            //     chartRef.current.timeScale()?.subscribeVisibleTimeRangeChange(debounce(timeRange => {
            //         if (timeRange !== null) {
            //             const date = formatTimeRangeToStr(timeRange)
            //             if (date && dateVisibleRange && dateRangeUtils.isIntersection(date, dateVisibleRange)) {
            //                 setDateVisibleRange(date)
            //             }
            //         }
            //     }, 100))
            // } catch (error) {
            //     console.warn('subscribeVisibleTimeRangeChange error', error)
            // }
        }

        handleChartTooltip({
            chartContainerEl,
            chart: chartRef.current,
            seriesArr,
            tooltipLabelList,
            formatTooltipValue,
        })
        
        return () => {
            if (chartRef.current) {
                chartRef.current.remove();
            }
        };
    }, [ 
        chartHeight, chartData, dateVisibleRange,
        leftPriceScaleValue, rightPriceScaleValue, 
    ])

    /* ************************* 动态计算线图高度 ****************************** */
    const legendWrapperRef = useRef<HTMLDivElement>(null)

    const updateChartHeight = () => {
        const wrapper = document.querySelector(`.${financialChartWrapperClassName}`)
        if (!wrapper) return
        const wrapperHeight = wrapper.getBoundingClientRect().height
        const legendHeight = legendWrapperRef.current?.getBoundingClientRect().height || 16
        const shortcutDateGroupHeight = 51
        const height = wrapperHeight - legendHeight - shortcutDateGroupHeight
        setChartHeight(height)
    }

    useLayoutEffect(() => {
        updateChartHeight()
    }, [ loading ])

    /* ************************* 组件对外暴露的方法 ****************************** */
    const updateVisibleTimeRange = () => {
        if (!chartRef.current) return
        const timeRange = chartRef.current.timeScale().getVisibleRange()
        if (!timeRange) return
        const date = formatTimeRangeToStr(timeRange)
        if (date) {
            setDateVisibleRange(date)
        }
    }

    const updatePriceScaleVisibleRange = () => {
        if (!chartRef.current) return
        const priceScaleVisibleRange = {}
        
        const leftPriceRange = chartRef.current.priceScale('left').getVisibleRange()
        if (leftYAxisName && leftPriceRange?.maxValue) {
            priceScaleVisibleRange[leftYAxisName] = leftPriceRange
        }

        const rightPriceRange = chartRef.current.priceScale('right').getVisibleRange()
        if (rightYAxisName && rightPriceRange?.maxValue) {
            priceScaleVisibleRange[rightYAxisName] = rightPriceRange
        }

        setPriceScaleVisibleRange(priceScaleVisibleRange)
    }

    const getPriceScaleVisibleRange = () => {
        if (!chartRef.current) return
        const priceScaleVisibleRange = {}
        
        const leftPriceRange = chartRef.current.priceScale('left').getVisibleRange()
        if (leftYAxisName && leftPriceRange?.maxValue) {
            priceScaleVisibleRange[leftYAxisName] = leftPriceRange
        }

        const rightPriceRange = chartRef.current.priceScale('right').getVisibleRange()
        if (rightYAxisName && rightPriceRange?.maxValue) {
            priceScaleVisibleRange[rightYAxisName] = rightPriceRange
        }

        return priceScaleVisibleRange
    }

    const getLeftPriceScaleMaxRange = () => {
        if (!chartRef.current) return
        return leftPriceScaleMaxRange
    }

    useImperativeHandle(ref, () => ({
        updateVisibleTimeRange,
        updatePriceScaleVisibleRange,
        updateChartHeight,
        getPriceScaleVisibleRange,
        getLeftPriceScaleMaxRange,
    }));

    return (
        <div className={classNames(styles.wrapper, financialChartWrapperClassName)}>
            <Spin 
                spinning={loading}
                style={{ minHeight: height || 400, zIndex: 1 }}
            >
                <div className={styles.legendWrapper} ref={legendWrapperRef}>
                    {
                        legendList.map((legend, index) => {
                            return (
                                <div key={legend} className={styles.legendItem}>
                                    <div 
                                        className={styles.legendDot} 
                                        style={{ backgroundColor: legendColors[index] }} 
                                    />
                                    <div className={styles.legendLabel}>
                                        {legend}
                                    </div>
                                </div>
                            )
                        })
                    }
                    {legendAfter}
                </div>
                <Flex>
                    {
                        !!leftPriceScaleMaxRange?.maxValue && leftYAxisName && leftSliderVisible && (
                            <Flex vertical justify="start" onClick={e => e.stopPropagation()}>
                                <Slider
                                    vertical 
                                    range
                                    max={leftPriceScaleMaxRange.maxValue}
                                    min={leftPriceScaleMaxRange.minValue}
                                    value={[ leftPriceScaleValue.minValue, leftPriceScaleValue.maxValue ]}
                                    onChange={value => {
                                        setLeftPriceScaleValue({
                                            minValue: value[0],
                                            maxValue: value[1],
                                        })
                                    }}
                                    step={leftStep}
                                    style={{ height: 'calc(100% - 38px)' }}
                                    tooltip={{ formatter: null }}
                                />
                            </Flex>
                        )
                    }
                    <div style={{ flex: 1 }}>
                        <div
                            style={{ width: '100%', minWidth: '100%', minHeight: height || 400, position: 'relative' }}
                            ref={chartContainerRef}
                        />
                    </div>
                    {
                        !!rightPriceScaleMaxRange?.maxValue && rightYAxisName && rightSliderVisible && (
                            <Flex vertical justify="start">
                                <Slider
                                    vertical 
                                    range
                                    max={rightPriceScaleMaxRange.maxValue}
                                    min={rightPriceScaleMaxRange.minValue}
                                    value={[ rightPriceScaleValue.minValue, rightPriceScaleValue.maxValue ]}
                                    onChange={value => {
                                        setRightPriceScaleValue({
                                            minValue: value[0],
                                            maxValue: value[1],
                                        })
                                    }}
                                    step={rightStep}
                                    style={{ height: 'calc(100% - 38px)' }}
                                    tooltip={{ formatter: null }}
                                />
                            </Flex>
                        )
                    }
                </Flex>
                {
                    showShortcutDate && !tmpDateRangeValue && (
                        <ShortcutDateGroup 
                            value={dateVisibleRange}
                            onChange={value => {
                                setDateVisibleRange?.(value)
                                onDateRangeChange?.(value)
                            }}
                            maxDateRange={maxDateRange}
                        />
                    )
                }
            </Spin>
            {
                showShortcutDate && tmpDateRangeValue && (
                    <ShortcutDateGroupV2 
                        value={dateSelectedRange}
                        tmpDateRangeValue={tmpDateRangeValue}
                        onChange={value => {
                            onDateRangeChange?.(value)
                        }}
                    />
                )
            }
        </div>
    )
})

export default memo(FinancialChart)
