import { useFetch } from 'libs'
import { dateUtils } from 'norna-uikit'
import { useEffect } from 'react'

export const useImageLog = () => {
    const { patchFn } = useFetch()

    useEffect(() => {
        const interval = 30000
        const timer = setInterval(() => {
            const callIbm = window.nornaCallIbm || 0
            const callAws = window.nornaCallAws || 0 
            const applyIbm = window.nornaApplyIbm || 0
            const applyAws = window.nornaApplyAws || 0
            if (!callIbm && !callAws && !applyIbm && !applyAws) return
            const payload = [
                {
                    call: {
                        ibm: callIbm,
                        aws: callAws,
                    },
                    apply: {
                        ibm: applyIbm,
                        aws: applyAws,
                    },
                    date: dateUtils.today(),
                    env: window.location.hostname,
                },
            ]
            patchFn('/image_server_request', payload)
                .then(res => {
                    if (res?.error) return
                    window.nornaCallIbm = 0
                    window.nornaCallAws = 0
                    window.nornaApplyIbm = 0
                    window.nornaApplyAws = 0
                })
        }, interval)

        return () => {
            timer && clearInterval(timer)
        }
    }, [])

}

export const useFetchImageLog = () => {
    const { postFn } = useFetch()

    useEffect(() => {
        postFn('/image_server_request', {
            date: dateUtils.today(),
            env: window.location.hostname,
        })
            .then(res => {
                console.log('res', res)
            })
    }, [])
}
