import { Descriptions, Flex, Table, Tag } from 'antd'
import { DateRangePicker, getDateRangeValueByPeriod } from 'componentsv2/form-elements/DateRangePicker'
import { getMetricOptions } from 'componentsv2/GraphButton/components/GraphModal/utils'
import { TreeDropdown } from 'componentsv2/TreeDropdown'
import { PRICE_FILTER_ARRAY } from 'consts'
import { Button, MultiSelect, Select } from 'druikit'
import { uniqBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { storage } from 'utils/storage'
import { useFetch } from 'libs'
import { getPriceFilterKeyByValue, getPriceFilterValueByKey } from 'graphql/nornaapi'
import { downloadFileByAxios } from 'export'
import { loadingBar, useLoadingBar } from 'hooks'
import { dateUtils } from 'norna-uikit'

const getPricePerspectiveOptions = () => {
  return PRICE_FILTER_ARRAY.map(item => {
    return {
      value: item.val,
      label: item.label,
    }
  })
}

export default function Page() {
  useLoadingBar()
  
  const licenses = storage.getLicenses()
  const licenseOptions = [ ...licenses, { code: 'All', name: 'All' } ].map(item => ({ value: item.code, label: item.name }))
  const [ licenseValue, setLicenseValue ] = useState(storage.getCustomerVendor())

  const [ countryOptions, setCountryOptions ] = useState<any[]>([])
  const [ countryValue, setCountryValue ] = useState('')
  const [ genderOptions, setGenderOptions ] = useState<any[]>([])
  const [ genderValue, setGenderValue ] = useState<string[]>([])
  const [ availableSellers, setAvailableSellers ] = useState<any[]>([])
  const [ vendorOptions, setVendorOptions ] = useState<any[]>([])
  const [ vendorValue, setVendorValue ] = useState<string[]>([])
  const metricOptions = getMetricOptions()
  const [ metricValue, setMetricValue ] = useState<string[]>([ 'Options' ])
  const pricePerspectiveOptions = getPricePerspectiveOptions()
  const [ pricePerspectiveValue, setPricePerspectiveValue ] = useState(PRICE_FILTER_ARRAY[0]?.val)
  const [ dateRangeValue, setDateRangeValue ] = useState(getDateRangeValueByPeriod('oneWholeWeekAgo'))

  useDeepCompareEffect(() => {
    if (!licenses?.length) return
    const currentLicense = licenses.find(item => item.code === licenseValue)
    if (!currentLicense) return
    const { 
      available_region = [],
      available_target_group = [],
      available_competitor_sellers = [],
    } = currentLicense

    const countryOptions = [ ...available_region, 'All Countries' ].map(item => ({ value: item, label: item }))
    const countryValue = available_region[0]
    const genderOptions = available_target_group.map(item => ({ value: item, label: item }))
    const genderValue = available_target_group.slice(0, 1)
    const vendorOptions = available_competitor_sellers
      .filter(item => item.region === countryValue)
      .map(item => ({ value: item.vendor, label: item.name }))
    const vendorValue = available_competitor_sellers.slice(0, 1).map(item => item.vendor)

    setCountryOptions(countryOptions)
    setCountryValue(countryValue)
    setGenderOptions(genderOptions)
    setGenderValue(genderValue)
    setAvailableSellers(available_competitor_sellers)
    setVendorOptions(vendorOptions)
    setVendorValue(vendorValue)
  }, [ licenseValue, licenses, [] ])

  const columns = [
    {
      dataIndex: 'customer',
      title: 'License info',
      width: '60%',
      render(_, record) {
        return (
          <Descriptions column={3}>
            <Descriptions.Item label="License">{record?.query?.customer}</Descriptions.Item>
            <Descriptions.Item label="Country">{record?.query?.region}</Descriptions.Item>
            <Descriptions.Item label="Price perspective">{getPriceFilterKeyByValue(record?.query?.price_perspective)}</Descriptions.Item>
            <Descriptions.Item label="Gender">{record?.query?.target_group?.join(', ')}</Descriptions.Item>
            <Descriptions.Item span={2} label="Date range">{record?.query?.date}</Descriptions.Item>
            <Descriptions.Item span={3} label="Vendor">{record?.query?.competitors?.join(', ')}</Descriptions.Item>
            <Descriptions.Item span={3} label="Metric">{record?.query?.metrics?.join(', ')}</Descriptions.Item>
          </Descriptions>
        )
      },
    },
    {
      dataIndex: 'datetime',
      title: 'Datetime',
      render(_, record) {
        return (
          <Descriptions column={1} layout="vertical">
            <Descriptions.Item label="Create time">
              {dateUtils.format(record?.create_time, 'yyyy-MM-dd HH:mm:ss')}
            </Descriptions.Item>
            <Descriptions.Item label="Finished time">
              {dateUtils.format(record?.finished_time, 'yyyy-MM-dd HH:mm:ss')}
            </Descriptions.Item>
          </Descriptions>
        )
      },
    },
    {
      dataIndex: 'email',
      title: 'Submitter',
    },
    {
      dataIndex: 'status',
      title: 'Status',
      render(text) {
        if (text === 'done') {
          return <Tag color="green">{text}</Tag>
        }
        if (text === 'failed') {
          return <Tag color="red">{text}</Tag>
        }
        if (text === 'running') {
          return <Tag color="blue">{text}</Tag>
        }
        return <Tag>{text}</Tag>
      },
    },
    {
      dataIndex: 'action',
      title: 'Action',
      render(_, record) {
        return (
          <Flex gap={10}>
            {
              [ 'running', 'pending' ].includes(record.status) && (
                <Button onClick={() => onCancel(record._id)}>Cancel</Button>
              )
            }
            {
              [ 'done' ].includes(record.status) && (
                <Button onClick={() => onDownload(record._id)}>Download</Button>
              )
            }
          </Flex>
        )
      },
    },
  ]

  const { patchFn: cancelTask } = useFetch()

  const onCancel = async (taskId) => {
    await cancelTask(`/schedular/tasks/${taskId}`, {})
    await fetchSchedularTasks()
  }

  const onDownload = async (taskId) => {
    loadingBar.restart()
    await downloadFileByAxios({
      filename: 'Line-graph-report',
      payload: { url: `schedular/result/${taskId}` },
      method: 'GET',
    })
    loadingBar.done()
  }

  const { postFn: submitOrder } = useFetch()

  const onOrder = async () => {
    if (!licenseValue) return

    const payload = {
      url: '/dashboard/async_download_all_graph',
      query: {
        customer: licenseValue,
        region: countryValue,
        date: dateRangeValue,
      },
      data: {
        competitors: vendorValue,
        target_group: genderValue,
        metrics: metricValue,
        price_perspective: getPriceFilterValueByKey(pricePerspectiveValue),
      },
    }

    await submitOrder(payload.url, payload)
    fetchSchedularTasks()
  }

  const { getFn: listTasks, data: dataSource = [], loading } = useFetch()

  const fetchSchedularTasks = async () => {
    listTasks('/schedular/tasks')
  }

  useEffect(() => {
    fetchSchedularTasks()
  }, [])

  return (
    <Flex vertical gap={20}>
      <Flex gap={10} align="end">
        <Select 
          label="License"
          placeholder="License"
          options={licenseOptions}
          value={licenseValue}
          onChange={(value) => {
            setLicenseValue(value)
            if (value === 'All') {
              // country 自动设置为 All Countries
              setCountryValue('All Countries')
              // vendor 自动选择全部
              const vendorOptions = uniqBy(availableSellers.map(item => ({ value: item.vendor, label: item.name })), 'value')
              setVendorOptions(vendorOptions)
              setVendorValue(vendorOptions.map(item => item.value))
              // gender 自动选全部
              setGenderValue(genderOptions.map(item => item.value))
            }
          }}
        />
        <Select
          disabled={licenseValue === 'All'}
          label="Country"
          placeholder="Country"
          options={countryOptions}
          value={countryValue}
          onChange={value => {
            if (value === 'All Countries') {
              const vendorOptions = uniqBy(availableSellers.map(item => ({ value: item.vendor, label: item.name })), 'value')
              setVendorOptions(vendorOptions)
              setVendorValue(vendorOptions.slice(0, 1).map(item => item.value))
            } else {
              const vendorOptions = availableSellers
                .filter(item => item.region === value)
                .map(item => ({ value: item.vendor, label: item.name }))
              setVendorOptions(vendorOptions)
              setVendorValue(vendorOptions.slice(0, 1).map(item => item.value))
            }
            setCountryValue(value)
          }}
        />
        <MultiSelect
          disabled={licenseValue === 'All'}
          label="Vendor"
          placeholder="Vendor"
          options={vendorOptions}
          value={vendorValue}
          onChange={setVendorValue}
          minCount={1}
        />
        <MultiSelect
          disabled={licenseValue === 'All'}
          key={genderValue.join(',')}
          label="Gender"
          placeholder="Gender"
          options={genderOptions}
          value={genderValue}
          onChange={setGenderValue}
          minCount={1}
        />
        <TreeDropdown
          multiple
          label="Metric"
          placeholder="Metric"
          options={metricOptions}
          value={metricValue}
          onOk={setMetricValue}
        />
        <Select 
          label="Price perspective"
          placeholder="Price perspective"
          options={pricePerspectiveOptions}
          value={pricePerspectiveValue}
          onChange={setPricePerspectiveValue}
        />
        <DateRangePicker
          value={dateRangeValue}
          onChange={setDateRangeValue}
        />
        <Button
          type="danger"
          onClick={onOrder}
        >
          Order
        </Button>
        <Button
          type="danger"
          onClick={() => {
            fetchSchedularTasks()
          }}
        >
          Refresh
        </Button>
      </Flex>
      <Table 
        columns={columns}
        dataSource={dataSource}
        rowKey="_id"
        loading={loading}
      />
    </Flex>
  )
}
