import React, { CSSProperties, memo } from 'react'
import { Dropdown, DropdownOptionProps, DropdownType } from 'componentsv2/form-elements/Dropdown'
import { useCategoryOptions } from 'hooks/useOptions'
import { getCategoryOptions } from 'utils/optionsUtils'
import { CategoryDropdownProps } from './types'
import { isSoldOutVendorsPage } from 'global/Routes'
import { isEqual } from 'utils/array'
import { isShowSoldOutAnalyticsPage } from 'utils/privUtils'
import { TreeDropdown } from 'componentsv2/TreeDropdown'

export const CategoryDropdown = memo(({
    value,
    onChange,
    left,
    right,
    style,
    label = 'Category',
    type = DropdownType.SINGLE,
    className,
}: CategoryDropdownProps) => {
    const { categoryOptions = [], defaultCategoryValue } = useCategoryOptions()

    return (
        <Dropdown
            style={style}
            className={className}
            left={left}
            right={right}
            options={categoryOptions}
            title="Category"
            placeholder="Category"
            label={label}
            value={value || defaultCategoryValue}
            onChange={value => {
                const selectedOption = categoryOptions.find(item => item.key === value) as DropdownOptionProps
                onChange?.(value as string, selectedOption, categoryOptions)
            }}
        />
    )
})

CategoryDropdown.displayName = 'CategoryDropdown'

export const MultiCategoryDropdown = ({
    value,
    onChange,
    onItemChange,
    left,
    right,
    style,
    selectStyle,
    selectorStyle,
    selectorClassName,
    dropdownStyle,
    excludeTotal = true,
    minCount = 1,
    maxCount = 9999,
    disabled = false,
    multiple = false,
    showSelectAll = true,
    showClearAll = true,
    showOkButton = true,
}: {
    value?: string[];
    onChange?: (value: string[]) => void;
    onItemChange?: (value: string[]) => void;
    left?: number | boolean;
    right?: number | boolean;
    style?: CSSProperties;
    selectStyle?: CSSProperties;
    selectorStyle?: CSSProperties;
    selectorClassName?: string;
    dropdownStyle?: CSSProperties;
    excludeTotal?: boolean;
    minCount?: number;
    maxCount?: number;
    disabled?: boolean;
    multiple?: boolean;
    showSelectAll?: boolean;
    showClearAll?: boolean;
    showOkButton?: boolean;
}) => {
    let options = getCategoryOptions({ excludeTotal })

    if (isShowSoldOutAnalyticsPage() && isSoldOutVendorsPage()) {
        options = options.filter(item => item.key === 'Tops')
    }

    return (
        <TreeDropdown
            style={style}
            selectStyle={selectStyle}
            selectorStyle={selectorStyle}
            selectorClassName={selectorClassName}
            dropdownStyle={dropdownStyle}
            disabled={disabled}
            multiple={multiple}
            placeholder="Category"
            label="Category"
            showSelectAll={showSelectAll}
            showClearAll={showClearAll}
            left={left}
            right={right}
            minCount={minCount}
            level1MaxCount={maxCount}
            options={options}
            value={value}
            onChange={value2 => {
                if (isEqual(value2, value || [])) {
                    return
                }
                onChange?.(value2 as string[])
            }}
            onItemChange={value2 => {
                if (isEqual(value2, value || [])) {
                    return
                }
                onItemChange?.(value2 as string[])
            }}
            onOk={(value2: string[]) => {
                if (isEqual(value2, value || [])) {
                    return
                }
                onChange?.([ ...value2 ])
            }}
            showOkButton={showOkButton}
        />
    )
}
