import React, { CSSProperties, memo, useCallback, useRef, useState } from 'react'
import { SkeletonLoading } from 'components/loaders'
import { IMG_PLACEHOLDER } from 'consts'
import classNames from 'classnames'
import { disableLazyLoad } from 'features/filters/memoDataSlice'
import { useSelector } from 'react-redux'
import NornaImage from './NornaImage'
import { ImageProps } from './types'
import styles from './styles.module.scss'
import { getImgFullPath } from 'utils/imgUtils'
import { isIbmDefault } from 'configs/image'

export const LazyBgImg = memo(({
  id,
  className = '',
  src = IMG_PLACEHOLDER, 
  retryCount = 3,
  cropedImg = true, 
  skeletonStyle = {},
  onClick,
  width,
  height,
  onLoad,
  objectFit,
}: ImageProps) => {
  // 导出图片时禁用懒加载
  const stateOfDisableLazyLoad = useSelector(disableLazyLoad)

  // ibm 更快加载图片还是 aws 更快加载图片
  const [ , setFaster ] = useState<'ibm' | 'aws' | ''>('')
  const fasterRef = useRef<'ibm' | 'aws' | ''>('')
  // 是否加载 aws 图片
  const [ showLowerPriorityImg, setShowLowerPriorityImg ] = useState(false)

  const higherPriority = isIbmDefault ? 'ibm' : 'aws'
  const lowerPriority = isIbmDefault ? 'aws' : 'ibm'

  const sty: CSSProperties = {}
  if (width) {
    sty.width = width
  }
  if (height) {
    sty.height = height
  }

  return (
    <div
      id={id}
      style={sty}
      className={classNames({
        [styles.imgContainer]: true,
        [styles.noLimitSizeBg]: !cropedImg,
        lazyBgImg: true,
        [className]: true,
      })}
      onClick={() => onClick?.()}
    >
      {
        showLowerPriorityImg && (
          <NornaImage
              style={{ 
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  background: '#f8f7f5',
                  objectFit,
                  zIndex: fasterRef.current === lowerPriority ? 1 : 0,
              }}
              src={getImgFullPath({ src, isIbmDomain: lowerPriority === 'ibm', isKeepOriginalSize: !cropedImg, width, height })}
              lazy={!stateOfDisableLazyLoad}
              retry={2}
              onFinish={(error) => {
                if (fasterRef.current || error) return
                fasterRef.current = lowerPriority
                setFaster(lowerPriority)
                
                if (lowerPriority === 'ibm') {
                  window.nornaApplyIbm = (window.nornaApplyIbm || 0) + 1
                } else {
                  window.nornaApplyAws = (window.nornaApplyAws || 0) + 1
                }

                onLoad?.()
              }}
          />
        )
      }
      <NornaImage
          style={{ 
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              background: '#f8f7f5',
              objectFit,
              zIndex: fasterRef.current === higherPriority ? 1 : 0,
          }}
          src={getImgFullPath({ src, isIbmDomain: higherPriority === 'ibm', isKeepOriginalSize: !cropedImg, width, height })}
          lazy={!stateOfDisableLazyLoad}
          retry={retryCount}
          timeout={2500}
          onTimeout={useCallback(() => {
            setShowLowerPriorityImg(true)
          }, [])}
          onFinish={(error) => {
            if (fasterRef.current || error) return
            fasterRef.current = higherPriority
            setFaster(higherPriority)
            
            if (higherPriority === 'ibm') {
              window.nornaApplyIbm = (window.nornaApplyIbm || 0) + 1
            } else {
              window.nornaApplyAws = (window.nornaApplyAws || 0) + 1
            }
            
            onLoad?.()
          }}
      />
      {
        !fasterRef.current ? (
          <SkeletonLoading isLoading={!fasterRef.current} style={{ ...skeletonStyle }} />
        ) : null
      }
    </div>
  )
})

LazyBgImg.displayName = 'LazyBgImg'
