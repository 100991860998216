import { MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { cloneDeep, sortBy } from 'lodash'
import { getVendorNameByCode } from 'utils'
import { storage } from './storage'

/**
 * Returns the last item of the array
 * 
 * If it is an empty array, return undefined
 */
export const lastItem = (array: any[] = []) => {
  const keys = Object.keys(array)
  return array[keys[keys.length - 1]]
}

/**
 * 需求:
 * 1. customerSellerVendor 如果存在，要放在数组第一位
 * 2. market 如果存在，要放在数组最后一位
 * 3. 有 subbrand 的要划分到一起
 * 比较复杂的是这种多个 vendor 有 subbrand 时 ['A@a', 'C@a', 'B@b', 'D@b']
 * 简单的按照首字母排序就变成了 ['A@a', 'B@b', 'C@a', 'D@b']
 * 但实际情况想要的应该是相同的 subbrand 要排列在一起 ['A@a', 'C@a', 'B@b', 'D@b']
 * 4. 比如 bogner 的 subbrand 有分组需求，分组新产生的 vendor 要放在 bogner 的所有 subbrand 后面
 * ['A@bogner', 'B@bogner', 'Bogner Selected'] 
 * 
 * 示例1: vendorList 是字符串数组
 * ```
 * let vendorList = ['a', 'b', 'c']
 * vendorList = sortVendorListV2({vendorList})
 * ```
 * 
 * 示例2: vendor 是对象数组
 * ```
 * let vendorList = [
 *    {label: 'a'},
 *    {label: 'b'},
 *    {label: 'c'},
 * ]
 * vendorList = sortVendorListV2({vendorList, vendorField: 'label'})
 * ```
 * 
 * 示例3: vendor 是更复杂的对象数组
 * ```
 * let vendorList = [
 *    {seller: {vendor: 'a'}},
 *    {seller: {vendor: 'b'}},
 *    {seller: {vendor: 'c'}},
 * ]
 * vendorList = sortVendorListV2({vendorList, vendorField: 'seller.vendor'})
 * ```
 */
export function sortVendorList({ vendorList = [], vendorField = '', customerVendor = '', marketName=MARKET_VENDOR.vendor, vendorOrder = [] }: { vendorList: any[],marketName?:string, vendorField?: string; customerVendor?: string; vendorOrder?: string[] }): any {
  customerVendor = storage.getCustomerVendor()
  vendorOrder = vendorOrder?.length ? vendorOrder : storage.getVendorOrder()
  // 01 先转成字符串数组
  let newVendorList: string[] = []
  if (!vendorField) {
    newVendorList = [ ...vendorList ]
  } else {
    newVendorList = vendorList.map(item => getObjValue(item, vendorField))
  }

  const sortVendors = (vendorList, vendorOrder) => {
    let newVendorList = [ ...vendorList ]
    /**
     * 02 对 vendor 拆分成 vendor, brand, sortField, name
     * sortField 用于排序
     *    - 对于没有 subbrand 的 vendor, sortField 就是 vendor 本身
     *    - 对于有 subbrand 的 vendor, sortField 是 @ 后面的内容,
     *      比如 'Bogner Sports@bogner' 中 sortField 就是 bogner
     *      正常按照首字母排序 [A@bogner, B, C@bogner] -> bogner 的 subbrand 被拆开了
     *      添加 sortField 之后就可以解决 bogner subbrand 被拆开问题 -> [B, A@bogner, C@bogner]
     */
    const vendorObjList = newVendorList.filter(item => item).map(item => {
      // 'FIRE+ICE@Bogner'
      const itemArr = item.split('@')
      const vendor = itemArr[0]
      const brand = itemArr.length === 2 ? itemArr[1] : ''
      const vendorName = getVendorNameByCode(item)
      const vendorNamePrefix = vendorName.split('@')[0].trim()
      // 会根据这个字段进行排序
      const sortField = itemArr.length === 2 ? itemArr[1] : itemArr[0]
      return { vendor, brand, sortField: sortField.toLowerCase(), name: item, vendorName, vendorNamePrefix }
    })
    let sortedVendorObjList = sortBy(vendorObjList, [ 'sortField', 'vendorName' ])

    const customerVendorName = getVendorNameByCode(customerVendor)
    const samePrefixVendorObjList = sortedVendorObjList.filter(item => item.vendorNamePrefix === customerVendorName)
    sortedVendorObjList = sortedVendorObjList.filter(item => item.vendorNamePrefix !== customerVendorName)
    sortedVendorObjList = [ ...samePrefixVendorObjList, ...sortedVendorObjList ]
    // 排序后的 vendorList
    newVendorList = sortedVendorObjList.map(item => item.name)

    // vendorOrder 如果存在，按照 vendorOrder 进行排序
    if (Array.isArray(vendorOrder) && vendorOrder.length > 0) {
      const vendorWithBrands = vendorOrder.filter(item => item.includes('@'))
      vendorWithBrands.forEach(vendorCode => {
          const index = vendorOrder.findIndex(v => v === vendorCode)
          const vendorName = getVendorNameByCode(vendorCode)
          const [ , brand ] = vendorName.split('@')
          vendorOrder.splice(index, 0, brand)
      })
      vendorOrder = Array.from(new Set(vendorOrder))
      newVendorList = newVendorList.sort((a, b) => vendorOrder.indexOf(a) - vendorOrder.indexOf(b))
    }
    return newVendorList
  }

  const vendorOrderList = newVendorList.filter(item => vendorOrder.includes(item))
  const excludeVendorOrderList = newVendorList.filter(item => !vendorOrder.includes(item))
  newVendorList = [ 
    ...sortVendors(vendorOrderList, vendorOrder), 
    ...sortVendors(excludeVendorOrderList, []), 
  ]

  /**
   * 04 如果有自己, 自己要放在第一位
   */
  const customerItem = newVendorList.find(vendor => vendor === customerVendor)
  if (customerItem) {
    newVendorList = newVendorList.filter(vendor => vendor !== customerVendor)
    newVendorList.unshift(customerItem)
  }

  /**
   * 05 put the SELECTED_VENDORS at the last second position if exists
   */
  const selectedVendors = newVendorList.find(vendor => vendor===SELECTED_VENDORS)
  if(selectedVendors){
    newVendorList = newVendorList.filter(vendor => vendor !== SELECTED_VENDORS)
    newVendorList.push(selectedVendors)
  }

  /**
   * 06 put the MARKET_VENDOR at the last position if exists
   */
  const marketItem = newVendorList.find(vendor => vendor === marketName)
  if (marketItem) {
    newVendorList = newVendorList.filter(vendor => vendor !== marketName)
    newVendorList.push(marketItem)
  }

  /**
   * 07 如果参数是对象数组，保留排序还原数组
   */
  if (vendorField) {
    newVendorList = newVendorList.map(vendor => vendorList.find(item => getObjValue(item, vendorField) === vendor))
  }

  return newVendorList
}

export function isEqual(a1: string[], a2: string[]) {
  a1 = cloneDeep(a1)
  a2 = cloneDeep(a2)
  return JSON.stringify(a1.sort()) === JSON.stringify(a2.sort())
}

/**
 * a1 是否是 a2 的父集
 * 
 * a1 = ['a', 'b', 'c']
 * a2 = ['a', 'b']
 * a3 = ['a', 'd']
 * isSuperArray(a1, a2)   // => true
 * isSuperArray(a1, a3)   // => false
 */
export function isSuperArray(a1: string[], a2: string[]) {
  return a1.filter(item => a2.includes(item)).length === a2.length
}

/**
 * a1 对 a2 的差集, 即返回元素在 a1 中但不在 a2 中
 * 
 * a1 = ['a', 'b', 'c']
 * a2 = ['a', 'd']
 * difference(a1, a2)  // => ['b', 'c']
 */
export function difference(a1: string[], a2: string[]) {
  const result = a1.filter(item => !a2.includes(item))
  return Array.from(new Set(result))
}

/**
 * ```
 * item = {seller: {vendor: 'hm'}}
 * getObjValue(item, 'seller.vendor') => 'hm'
 * ```
 */
function getObjValue(obj, key = '') {
  if (!key) return obj
  const keyArr = key.split('.')
  // 暂时硬编码，未想到更优雅方式
  try {
    if (keyArr.length === 1) {
      return obj[keyArr[0]]
    } if (keyArr.length === 2) {
      return obj[keyArr[0]][keyArr[1]]
    } if (keyArr.length === 3) {
      return obj[keyArr[0]][keyArr[1]][keyArr[2]]
    } 
      return obj
    
  } catch (e) {
    return obj
  }
}

/**
 * 两个数组求交集，共同的部分
 * 
 * e.g.1 基础数据数组求交集
 * intersection([1, 2], [1, 3])      // [1]
 * intersection([1, 2, 3], [1])      // [1]
 * intersection([1], [1, 2, 3])      // [1]
 * 
 * e.g.2 对象数组求交集
 * intersection([{'name': 'a1'}], [{'name': 'a2'}], 'name')    // []
 * intersection([{'name': 'a1'}, {'name': 'a2'}], [{'name': 'a2'}], 'name')      // [{'name': 'a2'}]
 * intersection([{'name': 'a1'}], [{'name': 'a1'}, {'name': 'a2'}], 'name')      // [{'name': 'a1'}]
 */
export function intersection(arr1: any[], arr2: any[], field?: string) {
  if (!field) return arr1.filter(item => arr2.includes(item))

  return arr1.filter(item => item).filter(item => {
      if (typeof item !== 'object') {
          return arr2.includes(item)
      } 
          return !!arr2.find(item2 => item[field] === item2[field])
      
  })
}

/**
 * Determine if two arrays are not equal
 */
export function isNotEqual(a1: string[], a2: string[]) {
  a1 = cloneDeep(a1)
  a2 = cloneDeep(a2)
  if (!Array.isArray(a1) || !Array.isArray(a2)) return true
  return a1.sort().toString() !== a2.sort().toString()
}
