/**
 * 图片相关配置属性
 */

export const ibmImagePrefix = 'https://eu-de.functions.cloud.ibm.com/api/v1/web/6435fd49-9174-4454-97b4-a15a66b7c78a/ubuntu/image?'
export const awsImagePrefix = 'https://65y62uvvwr33ebmkhexpfe4wu40ejpsg.lambda-url.eu-north-1.on.aws/?'

// 默认图片地址
export const isIbmDefault = true
export const IMAGE_CLOUD_URL = isIbmDefault ? ibmImagePrefix : awsImagePrefix

