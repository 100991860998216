import React, { useState } from 'react'
import { SizedBox } from 'componentsv2/SizedBox'
import { Spin } from 'componentsv2'
import { QueryForm } from './components/QueryForm'
import { exportSelector } from './utils'
import { useDashboardData } from './hooks/useApi'
import { QueryNav } from './components/QueryNav'
import { MetricInfo } from './components/MetricInfo'
import { QueryCategory } from './components/QueryCategory'
import { useApiData, useExportPdfVisible, useInit, useModalVisible, usePovValue, useSettingKpiValue } from './hooks/useDynamicDashbordData'
import { useLoadingBar } from 'hooks'
import styles from './styles.module.scss'
import { Flex } from 'antd'
import { MetricCard } from './components/MetricInfo/components/MetricCard'
import MetricModalContent from './components/MetricInfo/components/MetricModal/MetricModalContent'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { handleData } from './components/MetricInfo/utils'
import { useFilterCurrency } from 'hooks/useFilter'

export const DynamicDashboard = () => {
    useInit()
    useLoadingBar()
    const { loading } = useDashboardData()
    const [ modalVisible ] = useModalVisible()

    const [ settingKapiValue ] = useSettingKpiValue()
    const [ currency ] = useFilterCurrency()
    const [ apiData ] = useApiData()
    const [ povValue ] = usePovValue()
    const [ exportPdfVisible ] = useExportPdfVisible()
    const [ dataSource, setDataSource ] = useState<any[]>([])

    useDeepCompareEffect(() => {
        const newData: any[] = handleData(apiData, currency, povValue)
        setDataSource(newData)
    }, [ apiData, currency, {} ])

    return (
        <div 
            id={exportSelector} 
            className={styles.wrapper}
        >
            <div className={styles.header} style={{ pointerEvents: modalVisible ? 'none' : 'auto' }}>
                <QueryForm 
                    className={styles.queryForm} 
                    loading={loading || exportPdfVisible} 
                />
                <QueryNav />
            </div>
            <Spin
                useSpinImg
                spinning={loading || exportPdfVisible} 
                className={styles.body}
            >
                <div style={{ pointerEvents: modalVisible ? 'none' : 'auto' }}>
                    <QueryCategory />
                </div>
                <SizedBox height={30} />
                <MetricInfo />
            </Spin>

            <div
                className={styles.wrapper}
                style={{ opacity: 0, height: 0, overflow: 'hidden' }}
            >
                <div id="expand-dynamic-dashboard-wrapper" style={{ height: 'fit-content' }}>
                    <div className={styles.header} style={{ position: 'unset' }}>
                        <QueryNav />
                    </div>
                    <Spin
                        spinning={false} 
                        className={styles.body}
                    >
                        <Flex vertical gap={30}>
                            <div>
                                <QueryCategory isExportReport />
                            </div>
                            <Flex wrap="wrap">
                                {
                                    dataSource.map(item => {
                                        if (!settingKapiValue.includes(item.title)) {
                                            return null
                                        }
                                        return (
                                            <MetricCard
                                                key={item?.title || ''}
                                                className={styles.cardItem}
                                                title={item?.title || ''}
                                                dataSource={item?.dataSource || []}
                                                showUnits={item.showUnits}
                                                description={item.description}
                                            />
                                        )
                                    })
                                }
                            </Flex>
                        </Flex>
                    </Spin>
                </div>
                {
                    exportPdfVisible && settingKapiValue.map((kpi) => {
                        return (
                            <MetricModalContent 
                                key={kpi}
                                kpi={kpi}
                                style={{ paddingInline: 40 }}
                                className={`expand-dynamic-dashboard-kpi-${kpi.replaceAll(' ', '_')}`}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}
